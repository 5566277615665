@import url('https://fonts.googleapis.com/css2?family=BioRhyme:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Combo&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

p {
    font-size: 18px;
    font-family: "BioRhyme";
}

select {
    background-color: rgba(0, 0, 0, .05) ;
    border-radius: 5px;
}
